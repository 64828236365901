import { clientApi } from '@/lib/client-api'
import { toast } from 'sonner'
import { socket } from '@/utils/socket'

const emitterHandleBlock = (toUserId: string | number) => { //TODO: operação necessária para avisar todos os clientes que teve alterações nos bloqueados, futuramente deve ser implementado via socket o block/unblock
  const payload = { to: { id: toUserId }}
  socket?.emit('change_in_blockers', payload)
}

export async function block(blockedUserId: number) {
  const result = clientApi<number[]>({
    url: 'blocked/block',
    method: 'POST',
    body: JSON.stringify({ blockedUserId })
  })
    .finally(() => {
      emitterHandleBlock(blockedUserId)
    })

  toast.promise(result, {
    loading: 'Processando..',
    success: () => 'Bloqueado com sucesso',
    error: () => 'Ocorreu um erro'
  })

}

export async function unblock(blockedUserId: number) {
  const result = clientApi<number[]>({
    url: 'blocked/unblock',
    method: 'POST',
    body: JSON.stringify({ blockedUserId })
  })
    .finally(() => {
      emitterHandleBlock(blockedUserId)
    })

  toast.promise(result, {
    loading: 'Processando..',
    success: () => 'Desbloqueado com sucesso',
    error: () => 'Ocorreu um erro'
  })
}

export async function listMyBlocked() {
  const { data: usersId } = await clientApi<number[]>({ url: 'blocked/my' })

  return usersId
}

export async function listBlockedMe() {
  const { data: usersId } = await clientApi<number[]>({ url: 'blocked/blocked-me' })

  return usersId
}