export function _calculateAge(birthday): number {
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime()
  const ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export function calculateAge(birthday: Date | string): number {
  const date = typeof birthday === 'string'
    ? new Date(birthday)
    : birthday

  const ageDifMs = Date.now() - date.getTime()
  const ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export function getFormatedDate(rawDate: string | Date, locale: Intl.LocalesArgument = 'pt-BR') {
  const date = typeof rawDate === 'string'
    ? new Date(rawDate)
    : rawDate

  return new Date(date).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}