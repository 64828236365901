import { clientApi } from '@/lib/client-api'
import { socket } from '@/utils/socket'
import { toast } from 'sonner'

export async function favorite(favoritedUserId: number) {
  const result = clientApi<number[]>({
    url: 'favorites/favorite',
    method: 'POST',
    body: JSON.stringify({ favoritedUserId })
  })

  const onSuccess = () => {
    socket.emit('on_favorited', { to: { id: favoritedUserId }})
    return 'Favoritado com sucesso'
  }

  toast.promise(result, {
    loading: 'Processando..',
    success: onSuccess,
    error: () => 'Ocorreu um erro'
  })
}

export async function unfavorite(favoritedUserId: number) {
  const result = clientApi<number[]>({
    url: 'favorites/unfavorite',
    method: 'POST',
    body: JSON.stringify({ favoritedUserId })
  })

  toast.promise(result, {
    loading: 'Processando..',
    success: () => 'Desfavoritado com sucesso',
    error: () => 'Ocorreu um erro'
  })
}

export async function listMyFavorites() {
  const { data: usersId } = await clientApi<number[]>({ url: 'favorites/my' })

  return usersId
}