import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

export const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyBcClZzvOufCjooc7dzTGKj7Icdsd53qbk',
  authDomain: 'prd-findsugar.firebaseapp.com',
  databaseURL: 'https://prd-findsugar-default-rtdb.firebaseio.com/',
  projectId: 'prd-findsugar',
  storageBucket: 'prd-findsugar.appspot.com',
  messagingSenderId: '531828482861',
  appId: '1:531828482861:web:e103f350e149581258e9a4',
  measurementId: 'G-2FQ6R70KQ0'
}

export const firebaseApp = initializeApp(firebaseConfig)
export const database = getDatabase(firebaseApp)