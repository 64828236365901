// store/socketStore.js
import { create } from 'zustand'
import { io, Socket } from 'socket.io-client'
import { getSession } from 'next-auth/react'
import { IMessage } from '@/app/(lang)/chat/Message'
import { SocketPayload } from '@/types/socket'

interface State {
  socket: Socket | null;
}

interface Actions {
  connect: () => void;
  disconnect: () => void;
}

export const useSocketStore = create<State & Actions>((set) => {
  let socket: Socket

  const connect = async () => {
    const session = await getSession()
    socket = io(`${process.env.NEXT_PUBLIC_SERVER_WS_CHAT_URL}/chat`, {
      autoConnect: true,
      transports: [ 'websocket' ],
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      auth: { token: `Bearer ${session?.access_token}` }
    })

    socket.on('connect', () => {
      socket.on('message', handleReceivedMessage)
      socket?.emit('quantityUnreadMessage')
    })

    const handleReceivedMessage = (payload: SocketPayload<IMessage>) => {
      if (Number(payload.data.from.userId) !== session?.user.id) {
        socket?.emit('received', { messageId: payload.data.id })
      }
    }

    set({ socket })
  }

  const disconnect = () => {
    if (socket) {
      socket.disconnect()
      set({ socket: null })
    }
  }

  return {
    socket: null,
    connect,
    disconnect
  }
})
