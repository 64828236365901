import { toast } from 'sonner'
import { socket } from '../utils/socket'
import { clientApi } from '@/lib/client-api'
import { IPrivatePicture } from './store/private-picture'

const emitterHandlePrivatePicture = (toUserId: string | number) => { //TODO: operação necessária para avisar todos os clientes que teve alterações nos bloqueados, futuramente deve ser implementado via socket o block/unblock
  const payload = { to: { id: toUserId }}

  socket?.emit('change_to_see_my_private_pictures', payload)
  socket?.emit('list_request_private_pricture', payload)
}

export async function approve(id: string) {
  const result = clientApi<number[]>({
    url: `private-pictures/my/${id}`,
    method: 'PATCH',
    body: JSON.stringify({ status: 'APPROVED' })
  })
    .finally(() => {
      emitterHandlePrivatePicture(id)
    })

  toast.promise(result, {
    loading: 'Processando..',
    success: () => 'Aprovado com sucesso',
    error: () => 'Ocorreu um erro'
  })

}

export async function decline(id: string) {
  const result = clientApi<number[]>({
    url: `private-pictures/my/${id}`,
    method: 'PATCH',
    body: JSON.stringify({ status: 'REJECTED' })
  })
    .finally(() => {
      emitterHandlePrivatePicture(id)
    })

  toast.promise(result, {
    loading: 'Processando..',
    success: () => 'Recusado com sucesso',
    error: () => 'Ocorreu um erro'
  })
}

export async function listMyRequestPrivatePicture() {
  const { data: usersId } = await clientApi<IPrivatePicture[]>({ url: 'private-pictures/my' })

  return usersId
}

export async function listRequestPrivatePictureMe() {
  const data = await clientApi<IPrivatePicture[]>({ url: 'private-pictures/me' })

  return data.data
}