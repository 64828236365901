import { UserAvatar } from '@/components/UserAvatar'
import { IOnMessage } from './types'
import Link from 'next/link'

export function OnMessage(props: IOnMessage) {
  return (
    <Link href={`/chat/${props.room}`}>
      <div className='flex gap-2'>
        <UserAvatar src={props.from.picture || ''} username={props.from.username || ''} />
        <div className='flex flex-col gap-2'>
          <span>{props.from.username}</span>
          <span>{props.content}</span>
        </div>
      </div>
    </Link>
  )
}