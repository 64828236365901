

import axios, { AxiosRequestConfig } from 'axios';
import { getSession } from 'next-auth/react';

const baseURL = process.env.NEXT_PUBLIC_BASE_URL_LOCAL

const ApiClient = () => {
  const defaultOptions: AxiosRequestConfig = {
    baseURL
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(async (request) => {
    const session = await getSession();

    if (session) {
      request.headers.Authorization = `Bearer ${session.access_token}`;
    }

    return request;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    }
  );

  return instance;
};

export default ApiClient();