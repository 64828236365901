import { create } from 'zustand'
import { persist } from 'zustand/middleware'
export interface IPrivatePicture {
  id: number
  status: string
  from: { id: number }
  to: { id: number }
}

export type State = {
  myPrivate: IPrivatePicture[]
  privateMe: IPrivatePicture[]
}

export type Actions = {
  addMyPrivate: (privatePic: IPrivatePicture) => void
  addPrivateMe: (privatePic: IPrivatePicture) => void
  removeMyPrivate: (privatePic: IPrivatePicture) => void
  removePrivateMe: (privatePic: IPrivatePicture) => void
  populateMy: (privates: IPrivatePicture[]) => void,
  populatePrivateMe: (privates: IPrivatePicture[]) => void
}

export const usePrivatePictureStore = create<State & Actions>()(
  persist(
    (set) => ({
      myPrivate: [],
      privateMe: [],
      addMyPrivate: (privatePic: IPrivatePicture) => set((state) => ({ myPrivate: [ ...state.myPrivate, privatePic ] })),
      addPrivateMe: (privatePic: IPrivatePicture) => set((state) => ({ privateMe: [ ...state.privateMe, privatePic ] })),
      removeMyPrivate: (privatePic: IPrivatePicture) => set((state) => ({ myPrivate: state.myPrivate.filter((i) => i.id !== privatePic.id) })),
      removePrivateMe: (privatePic: IPrivatePicture) => set((state) => ({ privateMe: state.privateMe.filter((i) => i.id !== privatePic.id) })),
      populateMy: (myPrivate: IPrivatePicture[]) => set({ myPrivate }),
      populatePrivateMe: (privateMe: IPrivatePicture[]) => set({ privateMe })
    }),
    { name: 'private-pictures', skipHydration: true }
  )
)