import { create } from 'zustand'
import { persist } from 'zustand/middleware'
export type State = {
  unread: { id: string }[]
}

export type Actions = {
  changeUnread: (payload: { id: string }[]) => void
}

export const useChatStore = create<State & Actions>()(
  persist(
    (set) => ({
      unread: [],
      changeUnread: (unread: { id: string }[]) => set({ unread })
    }),
    { name: 'chat', skipHydration: true }
  )
)