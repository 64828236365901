import { create } from 'zustand'
import { persist } from 'zustand/middleware'
export type State = {
  myBlocked: number []
  blockedMe: number[]
}

export type Actions = {
  addMyBlocked: (userId: number) => void
  addBlockedMe: (userId: number) => void
  removeMyBlocked: (userId: number) => void
  removeBlockedMe: (userId: number) => void
  populateMy: (usersId: number[]) => void,
  populateBlockedMe: (usersId: number[]) => void
}

export const useBlockedStore = create<State & Actions>()(
  persist(
    (set) => ({
      myBlocked: [],
      blockedMe: [],
      addMyBlocked: (userId: number) => set((state) => ({ myBlocked: [ ...state.myBlocked, userId ] })),
      addBlockedMe: (userId: number) => set((state) => ({ blockedMe: [ ...state.blockedMe, userId ] })),
      removeMyBlocked: (userId: number) => set((state) => ({ myBlocked: state.myBlocked.filter((i) => i !== userId) })),
      removeBlockedMe: (userId: number) => set((state) => ({ blockedMe: state.blockedMe.filter((i) => i !== userId) })),
      populateMy: (myBlocked: number[]) => set({ myBlocked }),
      populateBlockedMe: (blockedMe: number[]) => set({ blockedMe })
    }),
    { name: 'blocked', skipHydration: true }
  )
)