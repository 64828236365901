'use client'

import { Button, ButtonProps } from '@/components/ui/button'
import Link from 'next/link'
import { ReactNode } from 'react'
import { cn } from '@/lib/utils'
import { usePathname } from 'next/navigation'

export const dynamic = 'force-dynamic'
export interface IMenuOptions extends ButtonProps {
  pathname: string
  label: string | ReactNode
  className: ButtonProps['className']
}

export function MenuList({ menuOptions }: { menuOptions: IMenuOptions[] }) {
  const path = usePathname()

  return (
    <>
      {
        menuOptions.map(({
          pathname,
          className,
          label,
          variant
        }) => (
          <Link
            href={pathname}
            key={pathname}
          >
            <Button
              variant={variant || 'ghost'}
              data-selected={path?.startsWith(pathname)}
              className={cn('flex items-center gap-2 [&>span]:text-lg', className)}
            >
              {label}
            </Button>
          </Link>
        ))
      }
    </>
  )
}