import { OnMessage } from './OnMessage'
import { INotification } from './types'

export function NotificationsRoot(props: INotification) {

  if (props.action === 'message') {
    return <OnMessage {...props} />
  }

  return null
}