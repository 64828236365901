import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonSignOut"] */ "/vercel/path0/components/ButtonSignOut.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderChat"] */ "/vercel/path0/components/HeaderV2/HeaderChat.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/MenuList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Socket"] */ "/vercel/path0/components/Socket.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/vercel/path0/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuItem"] */ "/vercel/path0/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Firebase"] */ "/vercel/path0/components/Utils/Firebase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BaselimeRum"] */ "/vercel/path0/node_modules/@baselime/react-rum/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/(lang)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/inter.ttf\",\"weight\":\"400\"}],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/Providers/NextAuthProvider.tsx");
