'use client'
import { useEffect } from 'react'
import { getAnalytics } from 'firebase/analytics'
import { firebaseApp } from '@/lib/firebase'

export function Firebase() {
  useEffect(() => {
    const analytics = getAnalytics(firebaseApp)
  }, [])

  return (
    <>
    </>
  )
}
