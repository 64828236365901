import { create } from 'zustand'
import { persist } from 'zustand/middleware'
export type State = {
  usersId: number[]
}

export type Actions = {
  addUser: (userId: number) => void
  removeUser: (userId: number) => void
  populate: (usersId: number[]) => void
}

export const useFavoritesStore = create<State & Actions>()(
  persist(
    (set) => ({
      usersId: [],
      addUser: (userid: number) => set((state) => ({ usersId: [ ...state.usersId, userid ] })),
      removeUser: (userid: number) => set((state) => ({ usersId: state.usersId.filter((id) => id !== userid) })),
      populate: (usersId: number[]) => set({ usersId })
    }),
    { name: 'my-favorites', skipHydration: true }
  )
)