import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { cn } from '@/lib/utils'
import Image from 'next/image'
import { TagVerified } from './Svg/TagVerified'

export function UserAvatar({ username, src, className, online, verified }: { username: string, src: string, className?: string, online?: boolean, verified?: boolean }) {
  return (
    <div className='relative'>
      <Avatar className={cn('relatve border size-10', className)}>
        <Image
          unoptimized
          alt={username}
          src={src || '/assets/avatar.svg'}
          width={150}
          height={150}
        />
        <AvatarFallback>{username?.slice(0, 2).toUpperCase()}</AvatarFallback>
      </Avatar>
      {
        online &&
        <div
          data-online={online}
          className="
            absolute
            top-0
            right-0
            rounded-full
            size-3
            border
            border-white
            bg-amber-300
            data-[online=true]:bg-green-900
          "
        />
      }
      {
        verified &&
        <TagVerified
          height={12}
          width={12}
          className="
            absolute
            bottom-0
            right-0
          "
        />
      }
    </div>
  )
}