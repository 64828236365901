'use client'

import { LuMessageSquare } from 'react-icons/lu'
import { useChatStore } from '../../lib/store/chat'

export function HeaderChat() {
  const { unread } = useChatStore((state) => ({ unread: state.unread }))

  return (
    <div className='flex relative gap-2 items-center'>
      <div className='relative'>
        {
          unread.length > 0 &&
          <div className='absolute flex items-center justify-center bg-red p-1 size-5 rounded-full text-[14px] top-[-10px] right-[-8px]'>
            <p>
              {
                unread.length > 9
                  ? <>9+</>
                  : unread.length
              }
            </p>
          </div>
        }
        <LuMessageSquare className='size-6' />
      </div>
      Conversas
    </div>
  )
}