import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type View = 'grid' | 'list'

export type State = {
  sound: boolean
  view: View
}

export type Actions = {
  changeView: (view: View) => void
  changeNotificationSound: (sound: boolean) => void
}

export const useUserPreferencesStore = create<State & Actions>()(
  persist(
    (set) => ({
      view: 'grid',
      sound: false,
      changeView: (view: View) => set({ view }),
      changeNotificationSound: (sound: boolean) => set({ sound })
    }),
    { name: 'user-preferences', skipHydration: true }
  )
)