'use client'
import { SessionProvider } from 'next-auth/react'
import { ReactNode } from 'react'
import ControlSessionProvider from './ControlSessionProvider'

export default function NextAuthProvider({ children }: { children: ReactNode }) {
  return (
    <SessionProvider>
      <ControlSessionProvider>
        {children}
      </ControlSessionProvider>
    </SessionProvider>
  )
}