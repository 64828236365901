'use client'

import {
  signOut,
  useSession
} from 'next-auth/react'
import { ReactNode, useEffect } from 'react'
import { toast } from 'sonner'

export default function ControlSessionProvider({ children }: { children: ReactNode }) {
  const { data: session } = useSession()
  useEffect(() => {
    if (session?.error === 'RefreshAccessTokenError') {
      toast.warning('Sessão expirada, acesse novamente!')
      signOut()
    }
  }, [ session ])

  return (
    <>
      {children}
    </>
  )
}