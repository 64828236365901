'use client'
import { getSession } from 'next-auth/react'

export interface IClientApi extends RequestInit {
  url: string | URL
}

export interface IResponseError {
  message: string
  status: number
  statusText: string
}

interface IResponseApiError {
  error: IResponseError
  data: undefined
}

interface IResponseApi<T> {
  data: T
  error: undefined
}

export const clientApi = async <T>(options: IClientApi): Promise<IResponseApi<T> | IResponseApiError> => {
  const path = typeof options.url === 'string'
    ? options.url?.replace(/^[\/]/, '')
    : options.url

  const response = await fetch(`/api/${path}`, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers
    }
  })

  if (!response.ok) {
    return await response?.json()?.then((error) => {
      return {
        data: undefined,
        error: {
          message: error.details?.message || error.message,
          status: response.status,
          statusText: error.details?.error || response.statusText
        }
      }
    })
  }

  const contentType = response.headers.get('content-type')

  if (contentType?.includes('application/json')) {
    return { data: await response.json(), error: undefined }
  }

  return { data: await response.text() as T, error: undefined }
}